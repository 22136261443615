import { ISuggestionsFormData } from 'app/common/presentation/screen/popUpManager/suggestionModal/form/SuggestionsFormData';
import { IAuth } from 'app/module/auth/domain/entity/Auth';
import ISuggestionRepository from 'app/module/suggestion/domain/interface/SuggestionRepository';

interface ISendSuggestionsUseCaseProps {
    auth: IAuth
    suggestionsFormData: ISuggestionsFormData
    suggestionRepository: ISuggestionRepository
}

export default async (props: ISendSuggestionsUseCaseProps): Promise<void> => {
    try {
        return await props.suggestionRepository.add(
            props.auth,
            {
                description: props.suggestionsFormData.suggestions
            }
        );
    } catch (error) {
        return Promise.reject(error);
    }
};
