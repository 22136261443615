import { useContext,useEffect,useState } from 'react';
import getAuthUserCase from 'app/module/auth/domain/useCase/GetAuthUseCase';
import getCurrentProfileUserCase from 'app/module/profile/domain/useCase/profile/GetCurrentProfileUseCase';
import setToastErrorUserCase from 'app/common/domain/useCase/SetToastErrorUseCase';
import { translateApiError } from 'app/common/domain/entity/Error';
import getNationalitiesUserCase from 'app/module/profile/domain/useCase/nationality/GetNationalitiesUseCase';
import { NationalityActions } from 'app/common/presentation/redux/nationality/NationalitySlice';
import { useDispatch } from 'react-redux';
import getProvincesUserCase from 'app/module/profile/domain/useCase/province/GetProvincesUseCase';
import { ProvinceActions } from 'app/common/presentation/redux/province/ProvinceSlice';
import getAgeConsentUseCase from 'app/common/domain/useCase/GetAgeConsentUseCase';
import handleIsFirstTimeUseCase from 'app/common/domain/useCase/HandleIsFirstTimeUseCase';
import { DependencyInjectionContext } from 'app/common/presentation/context/DependecyInjectionContext';
import { useAgeConsentConsentReduxState } from 'app/common/presentation/redux/ageConsent/hook/useAgeConsentConsentReduxState';
import ICookies from 'app/common/domain/entity/Cookies';
import { useCookiesConsentConsentReduxState } from 'app/common/presentation/redux/cookiesConsent/hook/useCookiesConsentConsentReduxState';
import getCookiesConsentUseCase from 'app/common/domain/useCase/GetCookiesConsentUseCase';

interface IUseInitialDataLoadResponse {
    isLoading: boolean
    hasAgeConsent: boolean
    cookiesConsent: ICookies
}
export const useInitialDataLoad = (): IUseInitialDataLoadResponse => {
    const dispatch = useDispatch();
    const dic = useContext(DependencyInjectionContext);
    const hasAgeConsent = useAgeConsentConsentReduxState();
    const cookiesConsent = useCookiesConsentConsentReduxState();
    const [isGettingAuth, setIsGettingAuth] = useState(true);
    const [isLoadingInitialData, setIsLoadingInitialData] = useState(true);
    const [isMounted, setIsMounted] = useState<boolean>(false);

    const getInitialData = async (): Promise<void> => {
        try {
            setIsLoadingInitialData(true);

            const [nationalities, provinces] = await Promise.all([
                getNationalitiesUserCase({
                    nationalityRepository: dic.nationalityRepository
                }),
                getProvincesUserCase({
                    provinceRepository: dic.provinceRepository
                }),
                handleIsFirstTimeUseCase({
                    storageRepository: dic.storageRepository
                }),
            ]);

            dispatch(NationalityActions.setNationalities(nationalities));
            dispatch(ProvinceActions.setProvinces(provinces));
        } catch (error) {
            setToastErrorUserCase(translateApiError(error as string));
        }
        finally{
            setIsLoadingInitialData(false);
        }
    };

    const checkIsAuthed = (): void => {
        getAuthUserCase({
            storageRepository: dic.storageRepository,
            authRepository: dic.authRepository,
        }).then(
            (data): void =>{
                getCurrentProfileUserCase({
                    auth: data,
                    profileRepository: dic.profileRepository
                }).then(
                    (): void => {
                        setIsGettingAuth(false);
                    }).catch((): void => {
                    setIsGettingAuth(false);
                });
            }).catch(()=>{
            setIsGettingAuth(false);
        });
    };

    useEffect((): void => {
        getCookiesConsentUseCase({
            storageRepository: dic.storageRepository
        });
        getAgeConsentUseCase({
            storageRepository: dic.storageRepository
        });
    }, []);

    useEffect((): void => {
        if(hasAgeConsent){
            setIsMounted(true);
            getInitialData().then(()=> {
                checkIsAuthed();
            });
        }
    }, [hasAgeConsent]);

    const isLoading = isLoadingInitialData || isGettingAuth || !isMounted;

    return {
        isLoading,
        hasAgeConsent,
        cookiesConsent,
    };
};
