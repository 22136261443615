import './RtaButton.scss';
import rtaImage from 'assets/icon/sexon/image/rta.png';
import { SexonButton } from '../button/SexonButton';

export const RtaButton = (): JSX.Element => {
    const openRTA = (): void => {
        const RTAUrl = 'https://rtalabel.org/';
        window.open(RTAUrl, '_blank');
    };

    return (
        <SexonButton
            type="button"
            onClick={openRTA}
            className="component-rta-image-button"
            title={(
                <img
                    alt="rtaImage"
                    src={rtaImage}
                />
            )}
        />
    );
};
