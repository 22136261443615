import React,{ useContext } from 'react';
import {
    CANCEL,
    REPORT,
    REPORT_MODAL_TEXT_P1,
    REPORT_MODAL_TEXT_P2,
    REPORT_MODAL_TITLE,
    REPORT_SUCCESSFUL_MESSAGE
} from 'translations/tr';
import { useTranslation } from 'react-i18next';
import './ReportModal.scss';
import { useDispatch } from 'react-redux';
import { ReportModalActions } from 'app/common/presentation/redux/reportModal/ReportModalSlice';
import { useForm } from 'react-hook-form';
import { IReportFormData } from 'app/common/presentation/screen/popUpManager/reportModal/form/ReportFormData';
import reportProfileUserCase from 'app/module/profile/domain/useCase/profile/ReportProfileUseCase';
import setToastErrorUserCase from 'app/common/domain/useCase/SetToastErrorUseCase';
import { translateApiError } from 'app/common/domain/entity/Error';
import setFullScreenSpinnerLoadingUserCase from 'app/common/domain/useCase/SetFullScreenSpinnerLoadingUseCase';
import setToastSuccessfulUserCase from 'app/common/domain/useCase/SetToastSuccessfulUseCase';
import { ESexonButtonStyle } from 'app/common/presentation/component/button/ESexonButtonStyle';
import { DependencyInjectionContext } from 'app/common/presentation/context/DependecyInjectionContext';
import { ReportModalForm } from './form/ReportModalForm';
import { useReportModalReduxState } from '../../../redux/reportModal/hook/useReportModalReduxState';
import { SexonModal } from '../../../component/modal/SexonModal';
import { SexonButton } from '../../../component/button/SexonButton';

export const ReportModal = (): JSX.Element => {
    const dispatch = useDispatch();
    const dic = useContext(DependencyInjectionContext);
    const { t } = useTranslation();
    const profileToReport = useReportModalReduxState();
    const UseReportModalForm = useForm<IReportFormData>(
        {
            defaultValues: {
                email: null,
                fullName: null,
                description: '',
                consentToGoodFaithAcceptance: false,
                legalNoticeAndPrivacyAcceptance: false,
            },
            shouldFocusError: true
        }
    );

    const handleCloseModal = (): void => {
        UseReportModalForm.reset();
        dispatch(ReportModalActions.handleHideReportModal());
    };

    const onSubmit = UseReportModalForm.handleSubmit((data)=> {
        if(profileToReport){
            setFullScreenSpinnerLoadingUserCase(true);
            reportProfileUserCase({
                data,
                profile: profileToReport,
                profileRepository: dic.profileRepository
            }).then(()=> {
                setToastSuccessfulUserCase(t(REPORT_SUCCESSFUL_MESSAGE));
            }).catch((error)=>{
                setToastErrorUserCase(translateApiError(error));
            }).finally(()=> {
                setFullScreenSpinnerLoadingUserCase(false);
                handleCloseModal();
            });
        }
    });

    return (
        <SexonModal
            show={ !!profileToReport }
            onClose={handleCloseModal}
            classname="component-report-modal"
            headerTitle={ t(REPORT_MODAL_TITLE , { username: profileToReport?.userName }) }
            body={
                <div className="report-modal-body-container">
                    <p>{t(REPORT_MODAL_TEXT_P1)}</p>
                    <p>{t(REPORT_MODAL_TEXT_P2)}</p>
                    <ReportModalForm
                        UseReportModalForm={UseReportModalForm}
                    />
                </div>
            }
            footer={ (
                <div className="report-modal-footer-container">
                    <SexonButton
                        title={ (
                            <span>
                                { t(CANCEL) }
                            </span>
                        ) }
                        type="button"
                        styling={ESexonButtonStyle.BASIC_PRIMARY}
                        onClick={handleCloseModal}
                    />
                    <SexonButton
                        title={ (
                            <span>
                                { t(REPORT) }
                            </span>
                        ) }
                        type="button"
                        styling={ESexonButtonStyle.FILLED}
                        onClick={onSubmit}
                    />
                </div>
            ) }
        />
    );
};
