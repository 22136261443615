const RegexResource = {
    MOBILE: /^[67]\d{8}$/,
    USERNAME: /^[a-zA-Z0-9._]+$/,
    EXTERNAL_LINK: /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]{1,}(\.[a-zA-Z]{2,})+([\\/?#][^\s]*)?$/,
    TELEGRAM_USERNAME: /^[A-Za-z][A-Za-z0-9_]{4,}$/,
    PROFILE_NAME: /^[a-zA-Z0-9áéíóúÁÉÍÓÚüÜñÑ._\s]+$/,
    EMAIL: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!¡?"¿@#$%^&*()_.'+-,/:;<>=+,¨€-])[^\s]{8,}$/,
};

export default RegexResource;
