import React,{ useEffect,useState } from 'react';
import { useDispatch } from 'react-redux';
import { ToastErrorActions } from 'app/common/presentation/redux/toast/ToastErrorSlice';
import { WarningIcon } from 'assets/icon/sexon/svg/icons';
import { useToastErrorReduxState } from '../../../redux/toast/hook/useToastErrorReduxState';
import { ESexonToastType,SexonToast } from '../../../component/toast/SexonToast';

export const ErrorToast = (): JSX.Element | null => {
    const [isClosing, setIsClosing] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const toastError = useToastErrorReduxState();
    const dispatch = useDispatch();

    const delayClose = (): void => {
        setIsClosing(true);
        setTimeout((): void => {
            dispatch(ToastErrorActions.setToastErrorMessage(''));
            setOpenModal(false);
            setIsClosing(false);
        }, 300);
    };

    useEffect(() => {
        if(toastError !== ''){
            setOpenModal(true);
        } else {
            delayClose();
        }
    }, [toastError]);

    return openModal ? (
        <SexonToast
            onClose={delayClose}
            message={toastError}
            isClosing={isClosing}
            icon={<WarningIcon />}
            type={ESexonToastType.ERROR}
            className="component-error-toast"
        />
    ) : null;
};
