import React from 'react';
import { CookiesPreferencesModal } from 'app/common/presentation/component/cookiesPreferencesModal/CookiesPreferencesModal';
import { HelpSectionModal } from 'app/common/presentation/screen/popUpManager/helpSectionModal/HelpSectionModal';
import { ErrorToast } from './errorToast/ErrorToast';
import { InfoToast } from './infoToast/InfoToast';
import { SuccessfulToast } from './successfulToast/SuccessfulToast';
import { ReportModal } from './reportModal/ReportModal';
import { RegisterModal } from './registerModal/RegisterModal';
import { SettingsModal } from './settingsModal/SettingsModal';
import { CreatePostModal } from './createPostModal/CreatePostModal';
import { SuggestionModal } from './suggestionModal/SuggestionModal';
import { FullScreenSpinner } from './fullScreenSpinner/FullScreenSpinner';

export const PopUpManagerScreen = (): JSX.Element=> {
    return (
        <>
            <ErrorToast />
            <InfoToast />
            <SuccessfulToast />
            <ReportModal />
            <RegisterModal />
            <SettingsModal />
            <CreatePostModal />
            <SuggestionModal />
            <HelpSectionModal />
            <FullScreenSpinner />
            <CookiesPreferencesModal />
        </>
    );
};
