import React,{ useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import {
    EReportFormDataName,
    IReportFormData
} from 'app/common/presentation/screen/popUpManager/reportModal/form/ReportFormData';
import { useTranslation } from 'react-i18next';
import { EReportReason,translateReportReason } from 'app/module/profile/domain/valueObject/ReportReason';
import { DescriptionIcon,EmailIcon } from 'assets/icon/sexon/svg/icons';
import {
    ENTER_A_VALID_EMAIL_ERROR,
    REPORT_MODAL_DESCRIPTION_TEXT,
    REPORT_MODAL_TEXT_P3,
    REPORT_MODAL_TEXT_P4,
    THIS_FIELD_IS_REQUIRED_ERROR
} from 'translations/tr';
import { EmailFormField } from 'app/module/profile/presentation/component/form/field/EmailFormField';
import { FullNameFormField } from 'app/module/profile/presentation/component/form/field/FullNameFormField';
import RegexResource from 'app/common/resource/RegexResource';
import { LegalNoticeAndPrivacyAcceptanceFormField } from 'app/module/profile/presentation/component/form/field/LegalNoticeAndPrivacyAcceptanceFormField';
import { ReportDeclarationAcceptanceFormField } from 'app/module/profile/presentation/component/form/field/ReportDeclarationAcceptanceFormField';
import { SexonTextArea } from '../../../../component/textArea/SexonTextArea';
import { useReportReasonsReduxState } from '../../../../redux/reportModal/hook/useReportReasonslReduxState';
import { SexonInputErrorMessage } from '../../../../component/inputErrorMessage/SexonInputErrorMessage';

interface IReportModalFormProps {
    UseReportModalForm: UseFormReturn<IReportFormData>
}

export const ReportModalForm = (props: IReportModalFormProps): JSX.Element => {
    const { t } = useTranslation();
    const reportReasons = useReportReasonsReduxState();
    const supportEmail = process.env.REACT_APP_SUPPORT_EMAIL;

    const watchFullName = props.UseReportModalForm.watch(EReportFormDataName.FULL_NAME);
    const watchReason = props.UseReportModalForm.watch(EReportFormDataName.REASON);
    const watchDescription = props.UseReportModalForm.watch(EReportFormDataName.DESCRIPTION);

    const reasonRegister = props.UseReportModalForm.register(EReportFormDataName.REASON,
        {
            required: t(THIS_FIELD_IS_REQUIRED_ERROR).toString()
        });
    const reasonError = props.UseReportModalForm.formState.errors.reason;

    const descriptionRegister = props.UseReportModalForm.register(EReportFormDataName.DESCRIPTION,
        {
            required: t(THIS_FIELD_IS_REQUIRED_ERROR).toString()
        });
    const descriptionError = props.UseReportModalForm.formState.errors.description;


    const fullNameRegister = props.UseReportModalForm.register(EReportFormDataName.FULL_NAME, {
        required: {
            value: watchReason === EReportReason.OTHER,
            message: t(THIS_FIELD_IS_REQUIRED_ERROR)
        },
    });
    const fullNameError = props.UseReportModalForm.formState.errors.fullName;

    const emailRegister = props.UseReportModalForm.register(EReportFormDataName.EMAIL, {
        required: {
            value: watchReason === EReportReason.OTHER,
            message: t(THIS_FIELD_IS_REQUIRED_ERROR)
        },
        pattern: {
            value: RegexResource.EMAIL,
            message: t(ENTER_A_VALID_EMAIL_ERROR).toString(),
        }
    });
    const emailError = props.UseReportModalForm.formState.errors.email;

    const legalNoticeAndPrivacyAcceptanceRegister = props.UseReportModalForm.register(
        EReportFormDataName.LEGAL_NOTICE_AND_PRIVACY_ACCEPTANCE, {
            required: t(THIS_FIELD_IS_REQUIRED_ERROR).toString(),
        });
    const legalNoticeAndPrivacyAcceptanceError = props.UseReportModalForm.formState.errors.legalNoticeAndPrivacyAcceptance;

    const consentToGoodFaithAcceptanceRegister = props.UseReportModalForm.register(
        EReportFormDataName.CONSENT_TO_GOOD_FAITH_ACCEPTANCE, {
            required: t(THIS_FIELD_IS_REQUIRED_ERROR).toString(),
        });
    const consentToGoodFaithAcceptanceError = props.UseReportModalForm.formState.errors.consentToGoodFaithAcceptance;

    useEffect(() => {
        props.UseReportModalForm.clearErrors();
        props.UseReportModalForm.resetField(EReportFormDataName.FULL_NAME);
        props.UseReportModalForm.resetField(EReportFormDataName.EMAIL);
    },[watchReason]);

    return (
        <form className="component-report-modal-form">
            <div className="report-modal-form-reason-container">
                {/* TODO: create radio button component */ }
                { reportReasons.map((reason) => (
                    <label
                        key={ reason.value }
                        htmlFor={ reason.value }
                        className="report-modal-form-reason-item"
                    >
                        <input
                            type="radio"
                            id={ reason.value }
                            { ...reasonRegister }
                            value={ reason.value }
                        />
                        <p>
                            { translateReportReason(reason.value) }
                        </p>
                    </label>
                )) }
            </div>
            <SexonInputErrorMessage showError={ !!reasonError } message={ reasonError?.message || '' }/>

            {
                watchReason === EReportReason.OTHER ? (
                    <>
                        <EmailFormField
                            isRequired
                            icon={ <EmailIcon/> }
                            register={ emailRegister }
                            showError={ !!emailError }
                            errorMessage={ emailError?.message || '' }
                        />

                        <FullNameFormField
                            register={ fullNameRegister }
                            showError={ !!fullNameError }
                            watchFullName={ watchFullName ?? '' }
                            errorMessage={ fullNameError?.message || '' }
                        />
                    </>
                ) : null
            }

            <p dangerouslySetInnerHTML={ { __html: t(REPORT_MODAL_TEXT_P3,{ supportEmail }).toString() } }/>

            <SexonTextArea
                isRequired
                maxLength={ 1000 }
                id="reportDescription"
                icon={ <DescriptionIcon/> }
                register={ descriptionRegister }
                watchRegister={ watchDescription }
                placeHolder={ t(REPORT_MODAL_DESCRIPTION_TEXT).toString() }
            />
            <SexonInputErrorMessage showError={ !!descriptionError } message={ descriptionError?.message || '' }/>

            <p>{ t(REPORT_MODAL_TEXT_P4) }</p>

            <LegalNoticeAndPrivacyAcceptanceFormField
                register={ legalNoticeAndPrivacyAcceptanceRegister }
                showError={ !!legalNoticeAndPrivacyAcceptanceError }
                errorMessage={ legalNoticeAndPrivacyAcceptanceError?.message || '' }
            />
            <ReportDeclarationAcceptanceFormField
                register={ consentToGoodFaithAcceptanceRegister }
                showError={ !!consentToGoodFaithAcceptanceError }
                errorMessage={ consentToGoodFaithAcceptanceError?.message || '' }
            />
        </form>
    );
};
