import React from 'react';
import { CloseIcon } from 'assets/icon/sexon/svg/icons';
import cx from 'classnames';
import './SexonToast.scss';
import { useTranslation } from 'react-i18next';
import { createPortal } from 'react-dom';
import { ERROR_LABEL,INFORMATION_LABEL,SUCCESS_LABEL } from 'translations/tr';
import { SexonButton } from '../button/SexonButton';

interface ISexonToastProps {
    message: string
    className: string
    icon: JSX.Element
    isClosing: boolean
    onClose: () => void
    type: ESexonToastType
}

export enum ESexonToastType {
    INFO='Info',
    ERROR='Error',
    SUCCESSFUL='Successful',
}
export const SexonToast = (props: ISexonToastProps): JSX.Element => {
    const { t } = useTranslation();

    const toastClassNames = cx({
        'component-toast': true,
        'toast-out': props.isClosing,
        'error-wrapper': props.type === ESexonToastType.ERROR,
        'successful-wrapper': props.type === ESexonToastType.SUCCESSFUL,
        'info-wrapper': props.type === ESexonToastType.INFO,
        [props.className]: !!props.className
    });

    const handleLabelTitle = (): string => {
        switch(props.type) {
            case ESexonToastType.ERROR:
                return t(ERROR_LABEL);
            case ESexonToastType.SUCCESSFUL:
                return t(SUCCESS_LABEL);
            case ESexonToastType.INFO:
            default:
                return t(INFORMATION_LABEL);
        }
    };

    return createPortal(
        <div className={toastClassNames}>
            <div className="toast-content-message-container">
                <div className="toast-icon-container">
                    {props.icon}
                    <span>{handleLabelTitle()}</span>
                </div>
                <span dangerouslySetInnerHTML={{ __html: props.message }} />
            </div>
            <SexonButton
                type="button"
                title={(<CloseIcon />)}
                onClick={props.onClose}
                className="toast-close-button"
            />
        </div>,
        document.body
    );
};
